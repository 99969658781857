import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import axios from 'axios';
import cn from 'classnames';

import { phoneFormConvert } from '1_shared/lib/helpers/phoneConvert';
import { Button, Input, Typography } from '1_shared/ui';

import { getUtm } from '../../../1_shared/helpers/getUtm';
import { CustomTypography } from '../../../1_shared/ui/CustomTypography';
import { schema } from '../config/schema';

import { IConsultationForm } from './interfaces/IConsultationForm';
import { IConsultProps } from './interfaces/IConsultProps';

import styles from './Consultation.module.scss';

const Consultation = (props: IConsultProps) => {
  const { control, handleSubmit, formState, reset } =
    useForm<IConsultationForm>({
      resolver: yupResolver(schema),
      mode: 'onChange',
    });
  const [messageApi, contextHolder] = useMessage();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: IConsultationForm, event: any) => {
    setLoading(true);
    const utm = getUtm();
    await axios.post(
      'https://api.dotherapy.ru/ns/api/v1/system-notifications/free-consultation',
      { ...data, utm: utm || undefined },
    );

    reset({
      name: '',
      phone: '',
      email: '',
    });
    if (props?.onCancel) props?.onCancel(event);
    setLoading(false);
    messageApi.success({
      content:
        'Благодарим за заявку. Наш специалист с Вами свяжется в ближайшее время',
    });
    // @ts-ignore
    ym(339913177, 'reachGoal', 'form_consultation_free');
  };

  return (
    <Modal className={styles.modal} {...props}>
      <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.description}>
          <Typography type="textM" component="strong">
            Запись на консультацию Службы заботы DoTherapy
          </Typography>
          <Typography type="p" component="h2">
            В рамках 15-минутной консультации наш психолог-консультант поможет
            определиться с запросом и подобрать специалиста, а так же ответит на
            основные вопросы по работе платформы
          </Typography>
        </div>
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              name="name"
              label={!value ? undefined : 'Имя'}
              value={value}
              onChange={onChange}
              variant="borderless"
              className={styles.borderless}
              placeholder="Имя"
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className={styles.input}>
              <Input
                name="phone"
                label={!value ? undefined : 'Номер телефона'}
                value={value}
                onChange={(e) => {
                  const val = e.target.value;
                  const convertedValue = phoneFormConvert(val);
                  onChange(convertedValue);
                }}
                variant="borderless"
                className={cn(styles.maskBorderless, styles.input)}
                placeholder="Номер телефона"
              />
              {error && (
                <CustomTypography type="description" className={styles.error}>
                  {error.message}
                </CustomTypography>
              )}
            </div>
          )}
        />


        <Controller
          name="email"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              name="email"
              label={!value ? undefined : 'E-mail'}
              value={value}
              onChange={onChange}
              variant="borderless"
              className={styles.borderless}
              placeholder="E-mail"
            />
          )}
        />
        <Button
          type="primary"
          htmlType="submit"
          disabled={!formState.isValid || loading}
          className={styles.button}
        >
          Оставить заявку
        </Button>
        <Typography className={styles.description} type="subtitle">
          *Нажимая на кнопку “Оставить заявку”, вы принимаете пользовательское
          соглашение
        </Typography>
        {contextHolder}
      </form>
    </Modal>
  );
};

export default Consultation;
