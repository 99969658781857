import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import { ISlot } from '../../../1_shared/config/interfaces/ISlot';
import { ReminderType } from '../../../1_shared/config/interfaces/RemainderType';
import { getCurrencyByRegion } from '../../../1_shared/helpers/RegionHelpers/getCurrencyByRegion';
import { phoneConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { CheckboxCircle } from '../../../1_shared/ui';
import useActionsOrder from '../model/useActionsOrder';

import { ILocationDetails } from './interfaces/ILocationDetails';

import 'app/styles/global/global.scss';
import './order-details.scss';

const OrderDetails = () => {
  const location = useLocation();
  const state = location.state as ILocationDetails;
  const { reminderSwitchAction } = useActionsOrder();

  const getSessionCalendarEvent = (slot: ISlot) => {
    const url = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'PRODID:-//hacksw/handcal//NONSGML v1.0//EN',
      'BEGIN:VEVENT',
      'SUMMARY:Запись на прием',
      'UID:19970610T172345Z-AF23B2@example.com',
      `DTSTART:${dayjs(slot.start).format('YYYYMMDDTHHmmss')}`,
      `DTEND:${dayjs(slot.end).format('YYYYMMDDTHHmmss')}`,
      'END:VEVENT',
      'END:VCALENDAR',
    ];

    const blob = new Blob([url.join('\n')], {
      type: 'text/calendar;charset=utf-8',
    });

    saveAs(blob, 'Запись на прием.ics');
  };

  return (
    <section className="order-details">
      <div className="container">
        <div className="order-details__inner">
          <h2 className="order-details__title section-title">
            Параметры вашего заказа
          </h2>

          <ul className="order-details__list">
            {state?.name && (
              <li className="order-details__item">
                <span className="order-details__label">
                  Ваши имя (псевдоним)
                </span>
                <p>{state.name}</p>
              </li>
            )}
            {state?.email && (
              <li className="order-details__item">
                <span className="order-details__label">Email</span>
                <p>{state.email}</p>
                {state?.slot?.slotId && (
                  <CheckboxCircle
                    onChange={() =>
                      reminderSwitchAction(
                        state?.slot?.slotId || '',
                        ReminderType.Email,
                      )
                    }
                  >
                    Оповещать
                  </CheckboxCircle>
                )}
              </li>
            )}
            {state?.phone && (
              <li className="order-details__item">
                <span className="order-details__label">Телефон</span>
                <p>{phoneConvert(state?.phone)}</p>
                {state?.slot?.slotId && (
                  <CheckboxCircle
                    onChange={() =>
                      reminderSwitchAction(
                        state?.slot?.slotId || '',
                        ReminderType.Phone,
                      )
                    }
                  >
                    Оповещать
                  </CheckboxCircle>
                )}
              </li>
            )}
            {state?.spec?.secondName && state?.spec?.firstName && (
              <li className="order-details__item">
                <span className="order-details__label">Специалист</span>
                <p>{`${state?.spec?.secondName} ${state?.spec?.firstName}`}</p>
              </li>
            )}
            {state?.slot && (
              <li className="order-details__item">
                <span className="order-details__label">Дата и время</span>
                <p>{dayjs(state.slot.start).format('DD MMMM, HH:mm')}</p>
                <button
                  type="button"
                  onClick={() => {
                    if (state?.slot) getSessionCalendarEvent(state?.slot);
                  }}
                  className="order-details__control order-details__control--calendar"
                >
                  Добавить в календарь
                </button>
              </li>
            )}
            {state?.cost !== undefined && (
              <li className="order-details__item">
                <span className="order-details__label">Стоимость</span>
                <p>{`${state?.cost} ${getCurrencyByRegion()}`}</p>
              </li>
            )}
          </ul>

          <div className="order-details__connect">
            <p>Если вы нашли неточность в данных, мы поможем исправить.</p>
            <a
              href="https://t.me/DoTherapy_chat"
              className="order-details__connect-btn btn btn--light btn--mini"
            >
              Напишите нам в Телеграмм
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderDetails;
