import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string()
  .matches(
    /\+7\s\d{3}\s\d{3}\s\d{2}\s\d{2}/,
    'Номер должен быть в виде +7 999 999 99 99',
  )
  .required(),
});
