import { useRef } from 'react';
import { useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'antd/lib/avatar/avatar';
import cn from 'classnames';

import LogoSvg from '1_shared/assets/pictures/logo.svg';
import LogoMobileSvg from '1_shared/assets/pictures/logo-mobile.svg';
import LogoUZMobile from '1_shared/assets/pictures/PsyMobileUZ.svg';
import { RoutePath } from '1_shared/config/routes';

import PsyUz from '../../../1_shared/assets/pictures/Psy-uz.svg';
import { Roles } from '../../../1_shared/config/interfaces/Roles';
import { isRegionUz } from '../../../1_shared/helpers/RegionHelpers/isRegionUz';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { headerLinks, headerLinksUZ } from '../config/headerLinks';

import { menuToggle } from './menu';

import 'app/styles/global/global.scss';
import './data-accordion.scss';
import './header.scss';

const Header = () => {
  const { user, role, logout } = useAuthContext();
  const navMain = useRef<any>();
  const navToggle = useRef<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const headerItems = isRegionUz() ? headerLinksUZ : headerLinks;

  return (
    <header
      ref={navMain}
      className={cn('header header--index', {
        'header-uz': isRegionUz(),
      })}
    >
      {/* на главной добавить класс header--index */}
      <nav className="header__nav container">
        <div className="header__top">
          <Link to={RoutePath.MAIN} className="header__logo">
            <picture>
              <source
                media="(max-width: 1023px)"
                srcSet={isRegionUz() ? LogoUZMobile : LogoMobileSvg}
              />
              {isRegionUz() ? (
                <img className="logo" src={PsyUz} alt="psy" />
              ) : (
                <img src={LogoSvg} alt="DoTherapy" width="100" height="39" />
              )}
            </picture>
          </Link>
          <button
            className="header__toggle"
            type="button"
            aria-label="Меню"
            ref={navToggle}
            onClick={() => menuToggle(navMain.current)}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <div className="header__wrapper">
          <div className="header__nav-list-wrap">
            <ul className="header__nav-list" data-accordion="parent">
              {headerItems.map(item =>
                item.isAccordion ? (
                  <li
                    key={item.label}
                    className="header__nav-item header__nav-item--dropdown"
                    data-accordion="element"
                  >
                    <a
                      href="/"
                      className="header__nav-link"
                      data-accordion="button"
                    >
                      {item.label}
                    </a>
                    <ul
                      className="header__nav-sublist"
                      data-accordion="content"
                    >
                      {item.children?.map(child => (
                        <li key={child.label} className="header__nav-subitem">
                          <Link to={child.url} className="header__nav-sublink">
                            {child.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li key={item.label} className="header__nav-item">
                    <a
                      href={item.url}
                      className="header__nav-link"
                      onClick={() => {
                        if (item.url === '#application') {
                          // @ts-ignore
                          ym(338452102, 'reachGoal', '15min_consultation');
                        }
                      }}
                    >
                      {item.label}
                    </a>
                  </li>
                ),
              )}
            </ul>
          </div>
          {isRegionUz() ? (
            <div />
          ) : (
            <div>
              {user ? (
                <ul className="header__nav-list" data-accordion="parent">
                  <li
                    className="header__nav-item header__nav-item--dropdown"
                    data-accordion="element"
                  >
                    <a
                      href="/"
                      className="header__nav-link--profile"
                      data-accordion="button"
                    >
                      <Avatar src={user?.avatarUrl} size={20} />{' '}
                      {role === Roles.Client
                        ? `${user?.isAnonymous || !user?.nickname ? 'Анононим' : user?.nickname}`
                        : `${user.firstName} ${user?.secondName}`}
                    </a>
                    <ul
                      className="header__nav-sublist"
                      data-accordion="content"
                    >
                      <li className="header__nav-subitem">
                        {role === Roles.Client ? (
                          <Link
                            to={RoutePath.CLIENT_CABINET}
                            className="header__nav-sublink"
                          >
                            Профиль
                          </Link>
                        ) : (
                          <a
                            href="https://go.dotherapy.ru/profile/sessions/"
                            className="header__nav-sublink"
                          >
                            Профиль
                          </a>
                        )}
                      </li>
                      <li className="header__nav-subitem">
                        <button
                          className="header__logout"
                          onClick={() => {
                            if (logout) {
                              logout();
                              if (location.pathname !== RoutePath.ORDER) {
                                navigate(RoutePath.MAIN);
                              }
                            }
                          }}
                          type="button"
                        >
                          <span className="header__nav-sublink">Выход</span>
                        </button>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                <Link
                  to={RoutePath.LOGIN}
                  className="header__login"
                  data-popup="popup-login"
                >
                  Войти
                </Link>
              )}
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
