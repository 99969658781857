import { Navigate } from 'react-router';
import {
  Anketa,
  ClientSessions,
  FirstFormSpec,
  LoginPage,
  LogoutPage,
  MainPage,
  NotFound,
  OrderPage,
  PcClient,
  PcSpec,
  ReactBetterPsy,
  ReactOrderCurs,
  ReactOrderOk,
  ReactOrderPage,
  SpecialistPersonPage,
  SpecialistsPage,
} from '5_pages';

import { RoutePath } from '1_shared/config/routes';

import { Roles } from '../../../1_shared/config/interfaces/Roles';
import ClientMainPage from '../../../5_pages/ClientMainPage/ui/ClientMainPage';
import ConferencePage from '../../../5_pages/Conference/ConferencePage';
import ReactOrderNotOk from '../../../5_pages/ReactOrderNotOk/ui/ReactOrderNotOk';

import { Route } from './interfaces/Route';

export const routesConfigUZ: Route[] = [
  {
    path: RoutePath.MAIN,
    element: <Navigate to={RoutePath.SPECIALISTS} />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.SPECIALISTS,
    element: <SpecialistsPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.SPECIALIST_PERSON,
    element: <SpecialistPersonPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.LOGIN,
    element: <LoginPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.PASSWORD,
    element: <LoginPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.CLIENT_CABINET,
    element: <PcClient />,
    roles: [Roles.Client],
  },
  {
    path: RoutePath.CLIENT_SESSIONS,
    element: <ClientSessions />,
    roles: [Roles.Client],
  },
  {
    path: RoutePath.SPECIALIST_CABINET,
    element: <PcSpec />,
    roles: [Roles.Spec],
  },
  {
    path: RoutePath.ORDER,
    element: <OrderPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.REACT_ORDER_CURS,
    element: <ReactOrderCurs />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.REACT_ORDER_OK,
    element: <ReactOrderOk />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.NEED_HELP,
    element: <ReactOrderNotOk />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.FIRST_FORM,
    element: <FirstFormSpec />,
    roles: [Roles.Spec],
  },
  {
    path: RoutePath.REACT_ANKETA,
    element: <Anketa />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.NOT_FOUND,
    element: <NotFound />,
    roles: [Roles.Unauthorized],
  },
];

export const routerConfig: Route[] = [
  {
    path: RoutePath.MAIN,
    element: <MainPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.CLIENT_MAIN,
    element: <ClientMainPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.LOGOUT,
    element: <LogoutPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.SPECIALISTS,
    element: <SpecialistsPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.ORDER,
    element: <OrderPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.NOT_FOUND,
    element: <NotFound />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.SPECIALIST_PERSON,
    element: <SpecialistPersonPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.LOGIN,
    element: <LoginPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.PASSWORD,
    element: <LoginPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.CLIENT_CABINET,
    element: <PcClient />,
    roles: [Roles.Client],
  },
  {
    path: RoutePath.CLIENT_SESSIONS,
    element: <ClientSessions />,
    roles: [Roles.Client],
  },
  {
    path: RoutePath.SPECIALIST_CABINET,
    element: <PcSpec />,
    roles: [Roles.Spec],
  },
  {
    path: RoutePath.REACT_ORDER_PAGE,
    element: <ReactOrderPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.REACT_ORDER_CURS,
    element: <ReactOrderCurs />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.REACT_ORDER_OK,
    element: <ReactOrderOk />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.NEED_HELP,
    element: <ReactOrderNotOk />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.FIRST_FORM,
    element: <FirstFormSpec />,
    roles: [Roles.Spec],
  },
  {
    path: RoutePath.REACT_ANKETA,
    element: <Anketa />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.REACT_BETTER_PSYCHOLOGICAL,
    element: <ReactBetterPsy />,
    roles: [Roles.Unauthorized],
  },
  {
    path: `${RoutePath.CONFERENCE}/:id`,
    element: <ConferencePage />,
    roles: [Roles.Client, Roles.Spec],
  },
];
