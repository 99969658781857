import React from 'react';

import { Achievement } from '../../../1_shared/ui/Achievement';
import { ISpecPersonCards } from '../../../4_widgets/SpecialistPersonCard/ui/interfaces/ISpecPersonCards';

import styles from './SpecialistAchievementList.module.scss';

const SpecialistAchievementsList: React.FC<ISpecPersonCards> = ({ spec }) => (
  <div className={styles.achievementsCardsList}>
    <Achievement
      title={'ЛЕТ\nС DOTHERAPY'}
      experience={Number(spec.yearsWithDoTherapy)}
    />
    <Achievement
      title={'ЛЕТ\nВ ТЕРАПИИ'}
      experience={Number(spec.experience)}
    />
  </div>
);

export default SpecialistAchievementsList;
