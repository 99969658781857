import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SWRConfig } from 'swr';

import { classNameRegion } from '1_shared/helpers/classNameRegion';
import useGetTheme from '1_shared/lib/theme';

import { env } from '../env';

import PrivateRouter from './module/provider/PrivateRouter';

import 'app/styles/globals.scss';

import 'dayjs/locale/ru';

dayjs.locale('ru');
dayjs.extend(customParseFormat);

const App = () => {
  const theme = useGetTheme();

  return (
    <ConfigProvider locale={ruRU} theme={theme}>
      <SWRConfig value={{ provider: () => new Map() }}>
        <main className={classNameRegion}>
          <PrivateRouter />
          <div className='version'>v.{env.REACT_APP_VERSION}</div>
        </main>
      </SWRConfig>
    </ConfigProvider>
  );
};
export default App;
