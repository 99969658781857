export enum AppRoute {
  Main = 'MAIN',
  ClientMain = 'CLIENT_MAIN',
  Specialists = 'SPECIALISTS',
  SpecialistPerson = 'SPECIALIST_PERSON',
  NotFound = 'NOT_FOUND',
  Order = 'ORDER',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Password = 'PASSWORD',
  SpecCab = 'SPECIALIST_CABINET',
  ClientCab = 'CLIENT_CABINET',
  ClientSessions = 'CLIENT_SESSIONS',
  FirstForm = 'FIRST_FORM',
  OrderPage = 'REACT_ORDER_PAGE',
  OrderCurs = 'REACT_ORDER_CURS',
  OrderOk = 'REACT_ORDER_OK',
  NeedHelp = 'NEED_HELP',
  Anketa = 'REACT_ANKETA',
  BetterPsychological = 'REACT_BETTER_PSYCHOLOGICAL',
  Conference = 'CONFERENCE',
}

export const RoutePath: Record<AppRoute, string> = {
  [AppRoute.Main]: '/',
  [AppRoute.ClientMain]: '/client',
  [AppRoute.Specialists]: '/specialists',
  [AppRoute.SpecialistPerson]: '/specialists/specialist/:id',
  [AppRoute.NotFound]: '*',
  [AppRoute.Order]: '/order',
  [AppRoute.Login]: '/login',
  [AppRoute.Logout]: '/logout',
  [AppRoute.Password]: '/signup/:jwt/:email',
  [AppRoute.SpecCab]: '/pc/spec',
  [AppRoute.ClientCab]: '/pc/client',
  [AppRoute.ClientSessions]: '/pc/client/sessions',
  [AppRoute.FirstForm]: '/first-form', // все ссылки которые начинаются с r пустые страницы для верстки
  [AppRoute.OrderPage]: '/r-order', // Запись на прием
  [AppRoute.OrderCurs]: '/r-order-curs', // Запись на курс
  [AppRoute.OrderOk]: '/order-ok', // Оплата прошла
  [AppRoute.NeedHelp]: '/need-help', // Все будет хорошо
  [AppRoute.Anketa]: '/anketa', //  Анкета
  [AppRoute.BetterPsychological]: '/r-better-psy', // Станьте лучше как психолог
  [AppRoute.Conference]: '/conference',
};
