import { phoneFormConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { MaskInput, Typography } from '../../../1_shared/ui';

import { IPhoneOrderInputPropsUz } from './interfaces/IPhoneOrderInputPropsUz';

import styles from './PhoneInput.module.scss';

const PhoneOrderInputUz = ({ value, onChange }: IPhoneOrderInputPropsUz) => (
    <div className={styles.row}>
      <Typography type="subtitle" className={styles.rowText}>
        ТЕЛЕФОН
      </Typography>
      <div className={styles.column}>
        <MaskInput
          size="middle"
          value={value}
          onChange={e => {
            const maskPhone = phoneFormConvert(e.target.value);
            onChange(maskPhone);
          }}
          mask=""
        />
      </div>
    </div>
  );

export default PhoneOrderInputUz;
