import React from 'react';

import { Content, Footer, Page } from '1_shared/ui';
import { Header, OrderForm } from '4_widgets';

import { isRegionUz } from '../../../1_shared/helpers/isRegionUz';
import OrderFormUz from '../../../4_widgets/OrderForm/ui/OrderFormUz';

const OrderPage = (): React.ReactElement => (
  <Page>
    <Header />
    <Content>{isRegionUz() ? <OrderFormUz /> : <OrderForm />}</Content>
    <Footer />
  </Page>
);

export default OrderPage;
